import React from "react";
import Aos from "aos";

class SemiCompliantPTCABalloonDilatationCatheter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        Aos.refresh();
      }, 2000);
    });
  };
  render() {
    return (
      <div className="MainContiner">
        <div>
          <div className="row ProductDetailContiner">
            <div data-aos="fade-right" className="col-sm-6 col-m-6 col-xl-6">
              <img
                alt="img"
                className="ProductImge animatedImg"
                src={require(`../../../Images/Semi-compliant PTCA Balloon Dilatation Catheter.png`)}
              />
            </div>
            <div data-aos="fade-left" className="col-sm-6 col-m-6 col-xl-6">
              <div className="caption">
                Semi-compliant PTCA Balloon Dilatation Catheter
              </div>
              <div className="discription">
                Sumicart CTO (RX type), a Semi-Compliant pre-dilatation balloon
                catheter with diameter sizes of 1.0mm-4.0mm, is designed for CTO
                and other chronic coronary stenosis. It is featured of
                extraordinary crossability, flexibility, pushability and rewrap.
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}
export default SemiCompliantPTCABalloonDilatationCatheter;
