import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../ComponentsStyle/HomePageSliderStyle.css";

const slides = [
  { imgUrl: "2.jpg", description: "Lorem ipsum 1" },
  { imgUrl: "3.jpg", description: "Lorem ipsum 2" },
  { imgUrl: "4.jpg", description: "Lorem ipsum 3" },
  { imgUrl: "5.jpg", description: "Lorem ipsum 2" },
];
class HomePageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="SliderContiner">
        <Slider autoplay infinite animateIn duration={5000}>
          {slides.map((slide, index) => (
            <div key={index} className="HomePageSlider">
              <img src={require(`../../Images/${slide.imgUrl}`)} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
export default HomePageSlider;
