/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Aos from "aos";

class Cardiovascular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        Aos.refresh();
      }, 2000);
    });
  };
  render() {
    return (
      <div className="MainContiner">
        <ul className="relatedProductlist" data-aos="fade-up">
          <li>
                <img
                  className="animatedImg"
                  src={require(`../../Images/Semi-compliant PTCA Balloon Dilatation Catheter.png`)}
                />
                <div className="name">Semi-compliant PTCA Balloon Dilatation Catheter</div>
                <div className="index-more">
                  <a href="/Semi-compliant-PTCA-Balloon-Dilatation-Catheter">Learn More +</a>
                </div>
              </li>
        </ul>
      </div>
    );
  }
}
export default Cardiovascular;
