import React from "react";
import "./Home.css";
import AboutUs from "../About-us/AboutUs";
import HomePageSlider from "../Components/HomePageSlider";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="MainContiner">
        <HomePageSlider />
        <AboutUs/>
      </div>
    );
  }
}
export default Home;
