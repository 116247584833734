import React from "react";
import "./AboutUsStyle.css";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="MainContiner" data-aos="fade-up">
        <div className="row AboutContiner">
          <div className="col-sm-6 col-m-6 col-xl-6">
            <div className="caption">About Us</div>
            <div className="discription">
              Headquartered in Toronto, Ontario, SUMICART has been focusing on
              the research and development, production, sales and service of
              medical products and providing customers with scientific knowledge
              of cardiovascular, general and endoscopic surgery and chronic
              disease management solutions since its inception. SUMICART now has
              more than 1,200 employees and a wide range of products for
              cardiovascular diseases, Cancer, infectious diseases, and plastic
              surger etc., widely sold in more than 100 countries and regions.
            </div>
          </div>
          <div className="col-sm-6 col-m-6 col-xl-6">
            <img src={require("../../Images/aboutus.jpg")}  style={{objectFit:"contain"}}/>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutUs;
