import React from "react";
import "../ComponentsStyle/HeaderStyle.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <nav className="bg-dark navbar navbar-dark navbar-expand-md navigation-clean">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={require("../../Images/logo.png")} />
          </a>
          <button
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navcol-1"
          >
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div id="navcol-1" className="collapse navbar-collapse">
            <ul className="navbar-nav">
              {/* ms-auto */}
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home Page
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/About-Us">
                  About Us
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="dropdown-toggle nav-link"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  href="/"
                >
                  Products
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="/Cardiovascular">
                    Cardiovascular
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <LogoChangeAlert /> */}
      </nav>
    );
  }
}
export default Header;
