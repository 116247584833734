import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "aos/dist/aos.css";
import "../src/app/General/Materials.css";
import "../src/app/General/ProductDetails.css";
import "../src/app/General/TableStyle.css";
import Home from "./app/Home/Home";
import Header from "./app/Components/Header";
import Footer from "./app/Components/Footer";
import AboutUs from "./app/About-us/AboutUs";
import Aos from "aos";
import Cardiovascular from "./app/Cardiovascular/Cardiovascular";
import SemiCompliantPTCABalloonDilatationCatheter from "./app/Cardiovascular/Products/SemiCompliantPTCABalloonDilatationCatheter";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    Aos.init({ duration: 2000, delay: 0, offset: 100, once: false });
  };
  render() {
    return (
        <div className="MainContiner">
          <Header />
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/About-Us" element={<AboutUs />} />
              <Route exact path="/Cardiovascular" element={<Cardiovascular />} />
              <Route exact path="/Semi-compliant-PTCA-Balloon-Dilatation-Catheter" element={<SemiCompliantPTCABalloonDilatationCatheter />} />
            </Routes>
          </Router>
          <Footer />
        </div>
    );
  }
}
export default App;
