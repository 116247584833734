import React from "react";
import "../ComponentsStyle/FooterStyle.css";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiFillInstagram,
} from "../General/Icons";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer class="footer-dark">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-md-3 item">
              <h3>Products</h3>
              <ul>
                {/* <li>
                  <a href="/">test</a>
                </li>
                 */}
              </ul>
            </div>
            <div class="col-sm-6 col-md-3 item">
              <h3>About</h3>
              <ul>
                <li>
                  <a href="/About-Us">About Us</a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 ContactUs">
              <div class="caption">Stay in touch</div>
              <ul>
                <li>
                  <em>Phone : </em>
                  <span>+1 647 877-3463</span>
                </li>
                <li>
                  <em>Email :</em>
                  <span>
                    <a href="mailto:info@sumicart.com" target="_blank">
                      info@sumicart.com
                    </a>
                  </span>
                </li>
                <li>
                  <em>Address : </em>
                  <span>504-439, Avenue, Toronto ON M5G 1Y8, Canada</span>
                </li>
              </ul>
              {/* <div class="caption">Follow Us</div> */}
              <div class="col item social">
                {"Follow Us"}
                <a href="#">
                  <AiFillFacebook />
                </a>
                <a href="#">
                  <AiFillTwitterSquare />
                </a>
                <a href="#">
                  <AiFillYoutube />
                </a>
                <a href="#">
                  <AiFillInstagram />
                </a>
              </div>
            </div>
          </div>
          <p class="copyright">
            sumicart. All rights reserved © 2022
          </p>
        </div>
      </footer>
    );
  }
}
export default Footer;
